html,body, #root, .App{
  height:100%
}
body {
  margin: 0;
  font-family: 'Nexa',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#fff;
  color: #082161;
}

#login-bg{
  background-image: url("./img/bg-provenio.jpg");
  height: 100%;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
}

#login{
  background-color:#fff;
  border-radius: 30px;
}


.App {
  text-align: center;
}

/*
td{
  width: 300px;
  height: 300px;
  padding: 0 20px;
}*/
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -4px;
  left: -1px;
  position: relative;
  background-color: #57cfff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.col-radio{
  width: 50px;
}

.EvalFormDiv{
  text-align: left;
  padding:50px;
}

.help-block, .alert{
  color:red;
  font-size:12px;
}