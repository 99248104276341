@font-face {
  font-family: 'Nexa';
  src: local('Nexa-Bold'), url(./fonts/Nexa-Bold.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'Nexa';
  src: local('Nexa-Light'), url(./fonts/Nexa-Light.otf) format('opentype');
  font-weight: normal;
}
